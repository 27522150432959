import { useEffect, useRef, useState } from 'react';
import _throttle from 'lodash/throttle';

const DEFAULT_VALUE = 1;
const useCoefficientRem = () => {
  const ref = useRef(DEFAULT_VALUE);
  const [value, setValue] = useState<number>(DEFAULT_VALUE);

  useEffect(() => {
    const root = document.querySelector('html');
    if (!root) {
      return;
    }

    const handler = () => {
      const size = (parseFloat(root.style.fontSize) || DEFAULT_VALUE) / 10;

      if (ref.current !== size) {
        ref.current = size;
        setValue(size);
      }
    };

    const handlerThrottled = _throttle(handler, 200);

    handler();
    window.addEventListener('resize', handlerThrottled);
    return () => {
      window.removeEventListener('resize', handlerThrottled);
    };
  }, []);

  return value;
};

export default useCoefficientRem;
