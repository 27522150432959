import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'StoreMapDetailsHeaderStyles-cvaRoot',
  'px-2.4 sm:px-3.2 lg:px-8 pt-3.2 sm:pt-4.8 pb-3.2',
  'w-full',
  'bg-cLightGrey',
]);

export const cvaTitle = cva(['StoreMapDetailsHeaderStyles-cvaTitle', 'mb-2.4', 'text-h3 font-secondary']);

export const cvaAddress = cva([
  'StoreMapDetailsHeaderStyles-cvaAddress',
  'block w-full',
  'mb-0.4',
  'text-bodyM !font-semibold',
]);

export const cvaWorkingHours = cva([
  'StoreMapDetailsHeaderStyles-cvaWorkingHours',
  'inline-block',
  'relative',
  'pl-2.4',
  'mb-0.4',
  'text-bodyM text-cBlack text-opacity-[0.48]',
]);

export const cvaWorkingHoursIcon = cva([
  'StoreMapDetailsHeaderStyles-cvaWorkingHoursIcon',
  'absolute left-0 top-1/2 -translate-y-1/2',
  'w-1.6 h-1.6',
  'fill-cBlack opacity-[0.48]',
]);

export const cvaPhone = cva([
  'StoreMapDetailsHeaderStyles-cvaPhone',
  'relative',
  'pl-2.4',
  'mb-2.4',
  'text-bodyM !font-semibold text-cMediumGreen',
  'flex',
]);

export const cvaPhoneIcon = cva([
  'StoreMapDetailsHeaderStyles-cvaPhoneIcon',
  'absolute top-1/2 left-0 -translate-y-1/2',
  'w-1.6 h-1.6',
]);

export const cvaHowToGet = cva(['StoreMapDetailsHeaderStyles-cvaHowToGet', 'mb-2.4']);

export const cvaHowToGetTitle = cva(['StoreMapDetailsHeaderStyles-cvaHowToGetTitle', 'text-bodyM !font-semibold']);

export const cvaHowToGetDescr = cva([
  'StoreMapDetailsHeaderStyles-cvaHowToGetDescr',
  'text-bodyM text-cBlack text-opacity-[0.48]',
]);

export const cvaTimeToStore = cva(['StoreMapDetailsHeaderStyles-cvaTimeToStore', '']);

export const cvaTimeToStoreTitle = cva([
  'StoreMapDetailsHeaderStyles-cvaTimeToStoreTitle',
  'text-bodyM text-cBlack text-opacity-[0.48]',
]);

export const cvaTimeToStoreDescr = cva([
  'StoreMapDetailsHeaderStyles-cvaTimeToStoreDescr',
  'text-bodyM !font-semibold',
]);
