import StoreMapDetailsBody from '@/entities/StoreMapDetailsBody/ui/StoreMapDetailsBody';
import StoreMapDetailsHeader from '@/entities/StoreMapDetailsHeader/ui/StoreMapDetailsHeader';
import StoreMapItem from '@/entities/StoreMapItem/ui/StoreMapItem';
import loadYmaps from '@/features/loadYMaps';
import { IProductCart } from '@/shared/api/getProduct/types';
import { ICity } from '@/shared/api/getShops/types';
import { IAmountItem } from '@/shared/api/postAvailByCity/types';
import { useMediaValues } from '@/shared/hooks/useMediaValues';
import useCoefficientRem from '@/shared/hooks/useRem';
import { useCartStore } from '@/shared/store/cartStore';
import { useCityStore } from '@/shared/store/cityStore';
import { Button } from '@/shared/ui/Button';
import LoadingSpinner from '@/shared/ui/LoadingSpinner/LoadingSpinner';
import ChooseCity from '@/widgets/ChooseCity/ui/ChooseCity';
import MobilePhotosSwiper from '@/widgets/MobilePhotoSwiper/ui/MobilePhotosSwiper';
import { cvaLoadingContainer } from '@/widgets/PvzMap/ui/PvzMapStyles';
import { initClusterer } from '@/widgets/StoresMap/utils/initClusterer';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import { motion } from 'framer-motion';
import { useLenis } from 'lenis/react';
import Image from 'next/image';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useDebounce, useMedia, useMount } from 'react-use';
import {
  cvaAside,
  cvaAsideButton,
  cvaAsideButtonWrapper,
  cvaAsideHeader,
  cvaAsideHeaderActiveCity,
  cvaAsideHeaderTitle,
  cvaAsideItems,
  cvaAsideScroll,
  cvaBack,
  cvaBackIcon,
  cvaBackWrapper,
  cvaBottomSheetHeader,
  cvaClose,
  cvaCloseIcon,
  cvaCloseWrapper,
  cvaMap,
  cvaMapWrapper,
  cvaRoot,
  cvaSearch,
  cvaSearchIcon,
  cvaSearchInput,
  cvaSheet,
  cvaStoreDetails,
  cvaZoom,
  cvaZoomButton,
  cvaZoomButtonIcon,
} from './StoresMapStyles';
import IconArrowLeft from '/public/icons/arrow-right-2.svg';
import IconClose from '/public/icons/close.svg';
import IconMinus from '/public/icons/minus.svg';
import IconPlus from '/public/icons/plus2.svg';
import IconSearch from '/public/icons/search3.svg';

interface Props {
  onClose: () => void;
  shops?: ICity[];
  amountShops?: IAmountItem[];
  activeCity?: string | null;
  onStoreChange?: (address: string, id: string) => void;
  defaultProduct?: any;
  typeI?: string;
  selectedProduct?: IProductCart;
  onCheckoutPage?: boolean;
  onMainPage?: boolean;
  cartTotal?: {
    quantity: number;
    summary: number;
  };
}

const StoresMap: FC<Props> = ({
  onClose: onCloseOrigin,
  shops,
  amountShops,
  activeCity,
  onStoreChange,
  typeI = 'default',
  selectedProduct,
  onCheckoutPage = false,
  onMainPage = false,
  cartTotal,
}) => {
  const sheetRef = useRef<any>(null);
  const [sheetVisible, setSheetVisible] = useState(false);

  const onClose = useCallback(() => {
    setSheetVisible(false);
    onCloseOrigin();
  }, [onCloseOrigin]);

  const coefficientRem = useCoefficientRem();
  const getProductsByIds = useCartStore((state) => state.getProductsByIds);

  const unavailableCart = useCartStore((state) => state.getUnavailableProducts);

  const scrollRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<any>(null);
  const clustererRef = useRef<any>(null);
  const zoomInRef = useRef<HTMLDivElement>(null);
  const zoomOutRef = useRef<HTMLDivElement>(null);

  const [showChooseCityPopup, setShowChooseCityPopup] = useState<boolean>(false);

  const [hideChooseCityControls, setHideChooseCityControls] = useState<boolean>(false);

  const [showBackArrow, setShowBackArrow] = useState<boolean>(false);

  const [stateBottomSheet, setStateBottomSheet] = useState<'top' | 'bottom'>('bottom');

  const [didMount, setDidMount] = useState<boolean>(false);
  const storesByCity = useMemo(() => {
    return shops?.find((store) => store.name === activeCity);
  }, [shops, activeCity]);

  const [addressSearchQuery, setAddressSearchQuery] = useState('');
  const [addressSearchQueryDebounced, setAddressSearchQueryDebounced] = useState('');
  const [searchedAddresses, setSearchedAddresses] = useState<any[]>([]);

  const [] = useDebounce(
    async () => {
      setAddressSearchQueryDebounced(addressSearchQuery);
    },
    300,
    [addressSearchQuery]
  );

  useEffect(() => {
    if (addressSearchQueryDebounced?.trim()) {
      let filteredAddresses = [];

      const searchQuery = addressSearchQueryDebounced.toLowerCase();

      if (storesByCity?.shops) {
        filteredAddresses = storesByCity.shops
          .filter((i) => i.address.toLowerCase().includes(searchQuery))
          .slice(0, 10);
      } else if (amountShops) {
        filteredAddresses = amountShops.filter((i) => i.store.address.toLowerCase().includes(searchQuery)).slice(0, 10);
      }

      // Если найдены результаты, устанавливаем их, иначе пустой массив
      setSearchedAddresses(filteredAddresses.length ? filteredAddresses : []);
    } else {
      // Если строка поиска пуста, сбрасываем результаты
      setSearchedAddresses([]);
    }
  }, [addressSearchQueryDebounced, storesByCity, amountShops]);

  useMount(() => {
    setDidMount(true);
  });

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const isMd = useMedia('(max-width: 1023px)');
  const rootLenis = useLenis()!;

  const [mapReady, setMapReady] = useState<boolean>(false);
  const [showStoreDetails, setShowStoreDetails] = useState<boolean>(false);
  const [tempActiveStore, setTempActiveStore] = useState<IAmountItem | null>(null);

  const setActiveStore = useCartStore((store) => store.setActiveStore);

  const activeStoreCenter = useMemo(() => {
    let coords =
      storesByCity?.coordinates ||
      [amountShops?.[0]?.store?.gps_n, amountShops?.[0]?.store?.gps_s].filter(Boolean).join(', ') ||
      undefined;

    if (coords === undefined) {
      coords = [amountShops?.[1]?.store?.gps_n, amountShops?.[1]?.store?.gps_s].filter(Boolean).join(', ');
    }

    return coords?.split(',');
  }, [storesByCity, amountShops]);

  useEffect(() => {
    loadYmaps(() => {
      if ((window as any).ymaps) {
        (window as any).ymaps.ready().then(() => {
          initMap();
          setMapReady(true);
        });
      }
    });

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.destroy();
      }
    };
  }, []);

  const initMap = () => {
    mapInstanceRef.current = new (window as any).ymaps.Map(
      mapRef.current,
      {
        center: activeStoreCenter,
        zoom: 10,
        controls: [],
      },
      {
        suppressMapOpenBlock: true,
        yandexMapDisablePoiInteractivity: true,
        minZoom: 3,
      }
    );

    // add zoomIn zoomOut events
    if (zoomInRef?.current) {
      zoomInRef?.current?.addEventListener('click', () => {
        mapInstanceRef.current.setZoom(mapInstanceRef.current.getZoom() + 1, {
          duration: 300,
        });
      });
    }
    if (zoomOutRef.current) {
      zoomOutRef.current.addEventListener('click', () => {
        mapInstanceRef.current.setZoom(mapInstanceRef.current.getZoom() - 1, {
          duration: 300,
        });
      });
    }
  };

  useEffect(() => {
    /* Обновляем центр карты после смены города */
    if (mapReady && mapInstanceRef.current && activeStoreCenter) {
      mapInstanceRef.current.setCenter(activeStoreCenter);
    }
  }, [mapReady, activeStoreCenter]);

  useEffect(() => {
    /* Обновляем кластер при изменении списка ПВЗ */
    if (mapReady && mapInstanceRef.current) {
      if (clustererRef.current) {
        /* Удаляем предыдущий */
        mapInstanceRef.current.geoObjects.remove(clustererRef.current);
      }

      /* Инициализируем новый */
      clustererRef.current = initClusterer({
        storesByCity,
        amountShops,
        onCityShopPlacemarkClick: (item) => {
          onClickStore(item);
          mapInstanceRef.current.setCenter(item.map.split(','));
          mapInstanceRef.current.setZoom(18, {
            duration: 300,
          });
        },
        onAmountShopPlacemarkClick: (item) => {
          onClickStore(item);
          mapInstanceRef.current.setCenter([item.store.gps_n, item.store.gps_s]);
          mapInstanceRef.current.setZoom(18, {
            duration: 300,
          });
        },
        calculateItemsCount,
        cartTotal,
      });

      /* Добавляем новый */
      mapInstanceRef.current.geoObjects.add(clustererRef.current);
    }
  }, [mapReady, storesByCity, amountShops]);

  const onClickStore = (item: any) => {
    setTempActiveStore(item);
    setShowStoreDetails(true);
  };

  const onClickSelectStore = () => {
    setActiveStore(tempActiveStore);
    onStoreChange && onStoreChange(tempActiveStore?.store.address || '', tempActiveStore?.store.store_id || '');
    onClose();
  };

  const onCloseDetails = () => {
    setActiveStore(null);
    setShowStoreDetails(false);
    setShowChooseCityPopup(false);
    setHideChooseCityControls(false);
    setShowBackArrow(false);
    if (isMd && didMount) {
      setSheetVisible(true);
    }
  };

  useEffect(() => {
    const refValue = scrollRef.current;
    const lockPaddingValue = window.innerWidth - document.body.offsetWidth + 'px';

    if (refValue && rootLenis) {
      rootLenis.stop();
      disableBodyScroll(refValue, {
        reserveScrollBarGap: true,
      });
      document.body.style.paddingRight = lockPaddingValue;
    }

    return () => {
      if (refValue && rootLenis) {
        enableBodyScroll(refValue);
        rootLenis.start();
        document.body.style.paddingRight = '0';
      }
    };
  }, []);

  const cityPopupInvisible = useCityStore((state) => state.cityPopupInvisible);

  // const setCityPopupInvisible = useCityStore(
  //   (state) => state.setCityPopupInvisible
  // );

  const handleCityClick = () => {
    // setCityPopupInvisible(true);
    setShowChooseCityPopup(true);
    setHideChooseCityControls(true);
    setShowBackArrow(true);
    if (isMd && didMount) {
      setSheetVisible(false);
    }
  };

  const cityName = useCityStore((state) => state.cityName);

  useEffect(() => {
    if (cityName === 'unknown') {
      setShowChooseCityPopup(true);
      setHideChooseCityControls(true);
      if (isMd && didMount) {
        setSheetVisible(false);
      }
    }
  }, [cityName, isMd, didMount]);

  const handleCityChange = () => {
    setShowChooseCityPopup(false);
    setHideChooseCityControls(false);
    setShowBackArrow(false);
    if (isMd && didMount) {
      setSheetVisible(true);
    }
  };

  const minHeightBottom: number = useMediaValues(
    {
      0: 184,
      768: 200,
    },
    0
  );

  const minHeightBottomSheet = useMemo(() => {
    return Math.round(minHeightBottom * coefficientRem);
  }, [coefficientRem, minHeightBottom]);

  const offsetHeader = useMemo(() => {
    return Math.round(72 * coefficientRem);
  }, [coefficientRem]);

  const onChangeStateBottomSheet = useCallback(() => {
    const state =
      sheetRef.current?.height === minHeightBottomSheet || sheetRef.current?.height === 0 ? 'bottom' : 'top';
    setStateBottomSheet(state);
  }, [sheetRef, minHeightBottomSheet]);

  const onSpringStart = useCallback(async () => {
    requestAnimationFrame(() => {
      onChangeStateBottomSheet();
    });
  }, [onChangeStateBottomSheet]);

  const onSpringEnd = useCallback(async () => onChangeStateBottomSheet(), [sheetRef, onChangeStateBottomSheet]);

  const onShowBottomSheet = useCallback(() => {
    sheetRef.current?.snapTo(({ lastSnap, maxHeight }) => {
      return lastSnap === minHeightBottomSheet || lastSnap === null ? maxHeight : minHeightBottomSheet;
    });
  }, [sheetRef, minHeightBottomSheet]);

  const onShowBottomSheetToTop = useCallback(
    () => sheetRef.current?.snapTo(({ maxHeight }) => maxHeight),
    [sheetRef.current]
  );

  const isCitiesLoading =
    amountShops && amountShops[0].store.address.split(',')[0].toLowerCase() !== activeCity?.toLowerCase();

  const cart = useCartStore((store) => store.cart);

  const calculateItemsCount = (item: IAmountItem) => {
    let total = 0;
    Object.keys(item.amount).forEach((key) => {
      total += Math.min(item.amount[key], cart.find((i) => Number(i.product_id) === Number(key))?.quantity || 0);
    });
    return total;
  };

  useEffect(() => {
    if (isMd && didMount) {
      return setSheetVisible(true);
    }
  }, [isMd, didMount]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
        ease: 'easeOut',
      }}
      className={cvaRoot({
        invisible: cityPopupInvisible,
      })}
      data-lenis-prevent
    >
      <div className={cvaMapWrapper()}>
        {!mapReady ? <LoadingSpinner size={'lg'} /> : null}
        <div ref={mapRef} className={cvaMap()}></div>
        {!showStoreDetails && !showBackArrow ? (
          <Button
            rootWrapperClassName={cvaCloseWrapper({ left: false })}
            className={cvaClose()}
            onClick={() => onClose()}
            color={'white'}
            hoverEffect={'highlight'}
            disableInViewAnimation
          >
            <IconClose className={cvaCloseIcon()} />
          </Button>
        ) : (
          <Button
            rootWrapperClassName={cvaBackWrapper()}
            className={cvaBack()}
            onClick={() => onCloseDetails()}
            color={'white'}
            hoverEffect={'highlight'}
            disableInViewAnimation
          >
            <IconArrowLeft className={cvaBackIcon()} />
          </Button>
        )}
        <div className={cvaZoom()}>
          <Button className={cvaZoomButton()} ref={zoomInRef} color={'white'} hoverEffect={'highlight'}>
            <IconPlus className={cvaZoomButtonIcon()} />
          </Button>
          <Button className={cvaZoomButton()} ref={zoomOutRef} color={'white'} hoverEffect={'highlight'}>
            <IconMinus className={cvaZoomButtonIcon()} />
          </Button>
        </div>
      </div>
      {showChooseCityPopup ? (
        <ChooseCity
          onClose={() => {}}
          hideOverlay
          hideControls={hideChooseCityControls}
          onCityChange={handleCityChange}
        />
      ) : (
        <div
          className={cvaAside({
            paddingBottom: showStoreDetails,
            showDetails: showStoreDetails,
          })}
          ref={scrollRef}
        >
          <div className={cvaAsideScroll()}>
            {!showStoreDetails ? (
              <div className={cvaAsideHeader()}>
                <div className={cvaAsideHeaderTitle()}>
                  Наши магазины{' '}
                  {activeCity !== 'unknown' ? (
                    <>
                      {'в '}
                      <span
                        className={cvaAsideHeaderActiveCity({ onMainPage })}
                        onClick={
                          onMainPage
                            ? () => {
                                return;
                              }
                            : handleCityClick
                        }
                      >
                        {activeCity}
                      </span>
                    </>
                  ) : null}
                </div>
                <div className={cvaSearch()}>
                  <input
                    type="text"
                    className={cvaSearchInput()}
                    placeholder={'Поиск по адресу'}
                    value={addressSearchQuery}
                    onChange={(e) => setAddressSearchQuery(e.target.value)}
                  />
                  <IconSearch className={cvaSearchIcon()} />
                </div>
              </div>
            ) : (
              <StoreMapDetailsHeader
                address={tempActiveStore?.store?.address || tempActiveStore?.address || ''}
                workingHours={tempActiveStore?.store?.mode || tempActiveStore?.mode || ''}
                phone={tempActiveStore?.store?.phone || tempActiveStore?.phone || ''}
                howToGet={tempActiveStore?.store?.description || tempActiveStore?.description || ''}
                timeToStore={'24 часа'}
              />
            )}

            {!showStoreDetails ? (
              <div className={cvaAsideItems()}>
                {searchedAddresses?.length && addressSearchQuery.length !== 0 ? (
                  searchedAddresses.map((item) => (
                    <StoreMapItem
                      key={item.id || item.store?.id}
                      address={item.address || item.store?.address}
                      date={
                        item.amount && Object.values(item.amount).filter((value) => value > 0).length > 0
                          ? 'Сегодня'
                          : ''
                      }
                      workingHours={item.mode || item.store?.schedule}
                      phone={item.phone || item.store?.phone}
                      itemsCount={item.amount ? calculateItemsCount(item) : 0}
                      allItems={item.amount ? cartTotal?.quantity || 0 : 0}
                      onClickStore={() => onClickStore(item)}
                    />
                  ))
                ) : addressSearchQuery.length !== 0 ? (
                  <div className="pl-8 pt-2.4 text-bodyM text-cBlack48">
                    К сожалению, подходящих магазинов не найдено.
                  </div>
                ) : (
                  <>
                    {storesByCity?.shops?.map((item) => (
                      <StoreMapItem
                        key={item.id}
                        address={item.address}
                        date={item.itemsCount > 0 ? 'Сегодня' : ''}
                        workingHours={item.mode}
                        phone={item.phone}
                        itemsCount={item.itemsCount}
                        allItems={item.allItems}
                        onClickStore={() => onClickStore(item)}
                      />
                    ))}
                    {isCitiesLoading ? (
                      <div className={cvaLoadingContainer()}>
                        <LoadingSpinner size={'md'} />
                      </div>
                    ) : (
                      <>
                        {amountShops?.map((item) => (
                          <StoreMapItem
                            key={item.store.id}
                            address={item.store.address}
                            date={Object.values(item.amount).filter((value) => value > 0).length > 0 ? 'Сегодня' : ''}
                            workingHours={item.store.schedule}
                            phone={item.store.phone}
                            itemsCount={calculateItemsCount(item)}
                            allItems={cartTotal?.quantity || 0}
                            onClickStore={() => onClickStore(item)}
                          />
                        ))}
                      </>
                    )}
                  </>
                )}
              </div>
            ) : typeI === 'default' ? (
              <>
                <div className={cvaStoreDetails()}>
                  {onCheckoutPage ? (
                    <StoreMapDetailsBody
                      pickupItems={
                        tempActiveStore?.amount
                          ? getProductsByIds(
                              Object.keys(tempActiveStore?.amount)
                                .filter((key) => tempActiveStore.amount[key] > 0)
                                .map(Number)
                            )
                          : []
                      }
                      otherDeliveryItems={
                        tempActiveStore?.amount
                          ? getProductsByIds(
                              Object.keys(tempActiveStore?.amount)
                                .filter((key) => tempActiveStore.amount[key] === 0)
                                .map(Number)
                            )
                          : []
                      }
                      notAvailableItems={unavailableCart()}
                    />
                  ) : (
                    <div className="w-[calc(100%-4.8rem)] mx-2.4 aspect-[312/221] relative">
                      {tempActiveStore?.photos?.length ? <MobilePhotosSwiper images={tempActiveStore.photos} /> : null}
                    </div>
                  )}
                </div>
              </>
            ) : selectedProduct ? (
              <div className={cvaStoreDetails()}>
                <div className="w-full px-8 py-4 flex flex-col gap-2.4">
                  <h4 className="font-secondary text-cBlack text-h4">
                    Можно забрать <span className="text-cMediumGreen">сегодня</span>
                  </h4>
                  {selectedProduct.detail_picture ? (
                    <div className="w-9 h-9 relative rounded-[1.6rem] bg-cLightGrey p-1">
                      <div className="relative w-full h-full">
                        <Image
                          src={selectedProduct.detail_picture}
                          className="object-contain mix-blend-multiply"
                          alt={selectedProduct.name}
                          fill
                        />
                      </div>
                    </div>
                  ) : selectedProduct.preview_picture ? (
                    <div className="w-9 h-9 relative rounded-[1.6rem] bg-cLightGrey p-1">
                      <div className="relative w-full h-full">
                        <Image
                          src={selectedProduct.preview_picture}
                          className="object-contain mix-blend-multiply"
                          alt={selectedProduct.name}
                          fill
                        />
                      </div>
                    </div>
                  ) : selectedProduct.photos ? (
                    <div className="w-9 h-9 relative rounded-[1.6rem] bg-cLightGrey p-1">
                      <div className="relative w-full h-full">
                        <Image
                          src={selectedProduct.photos[0]}
                          className="object-contain mix-blend-multiply"
                          alt={selectedProduct.name}
                          fill
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          {showStoreDetails &&
          tempActiveStore?.amount &&
          getProductsByIds(
            Object.keys(tempActiveStore?.amount)
              .filter((key) => tempActiveStore.amount[key] > 0)
              .map(Number)
          )?.length ? (
            <div className={cvaAsideButtonWrapper()}>
              <Button
                type="button"
                className={cvaAsideButton()}
                color={'mediumGreen'}
                rootWrapperClassName="w-full"
                animateWrapperClassName="w-full"
                size={'medium'}
                hoverEffect={'highlight'}
                onClick={() => onClickSelectStore()}
                disableInViewAnimation
              >
                Выбрать магазин
              </Button>
            </div>
          ) : null}
        </div>
      )}
      <BottomSheet
        ref={sheetRef}
        className={cvaSheet()}
        open={sheetVisible}
        snapPoints={({ maxHeight }) => [minHeightBottomSheet, maxHeight - offsetHeader]}
        onSpringStart={onSpringStart}
        onSpringEnd={onSpringEnd}
        blocking={false}
        header={
          <div
            className={cvaBottomSheetHeader({
              bottom: stateBottomSheet === 'bottom',
            })}
            onClick={onShowBottomSheet}
          />
        }
        data-lenis-prevent
      >
        <div className={cvaAsideScroll()}>
          {!showStoreDetails ? (
            <div className={cvaAsideHeader()}>
              <div className={cvaAsideHeaderTitle()}>
                Наши магазины
                <br /> в городе{' '}
                <span className={cvaAsideHeaderActiveCity({ onMainPage })} onClick={handleCityClick}>
                  {activeCity}
                </span>
              </div>
              <div className={cvaSearch()}>
                <input
                  type="text"
                  className={cvaSearchInput()}
                  placeholder={'Поиск по адресу'}
                  onChange={(e) => setAddressSearchQuery(e.target.value)}
                  onFocus={onShowBottomSheetToTop}
                />
                <IconSearch className={cvaSearchIcon()} />
              </div>
            </div>
          ) : (
            <StoreMapDetailsHeader
              address={tempActiveStore?.store?.address || tempActiveStore?.address || ''}
              workingHours={tempActiveStore?.store?.schedule || tempActiveStore?.schedule || ''}
              phone={tempActiveStore?.store?.phone || tempActiveStore?.phone || ''}
              howToGet={tempActiveStore?.store?.description || tempActiveStore?.description || ''}
              timeToStore={'24 часа'}
            />
          )}

          {!showStoreDetails ? (
            <div className={cvaAsideItems()}>
              {searchedAddresses?.length && addressSearchQueryDebounced.length !== 0 ? (
                searchedAddresses.map((item) => (
                  <StoreMapItem
                    key={item.id || item.store?.id}
                    address={item.address || item.store?.address}
                    date={
                      item.amount && Object.values(item.amount).filter((value) => value > 0).length > 0 ? 'Сегодня' : ''
                    }
                    workingHours={item.mode || item.store?.schedule}
                    phone={item.phone || item.store?.phone}
                    itemsCount={item.amount ? calculateItemsCount(item) : 0}
                    allItems={item.amount ? cartTotal?.quantity || 0 : 0}
                    onClickStore={() => onClickStore(item)}
                  />
                ))
              ) : addressSearchQueryDebounced.length !== 0 ? (
                <div className="px-3.2">Не удалось ничего найти</div>
              ) : (
                <>
                  {storesByCity?.shops?.map((item) => (
                    <StoreMapItem
                      key={item.id}
                      address={item.address}
                      date={item.itemsCount > 0 ? 'Сегодня' : ''}
                      workingHours={item.mode}
                      phone={item.phone}
                      itemsCount={item.itemsCount}
                      allItems={item.allItems}
                      onClickStore={() => onClickStore(item)}
                    />
                  ))}
                  {amountShops?.map((item) => (
                    <StoreMapItem
                      key={item.store.id}
                      address={item.store.address}
                      date={Object.values(item.amount).filter((value) => value > 0).length > 0 ? 'Сегодня' : ''}
                      workingHours={item.store.schedule}
                      phone={item.store.phone}
                      itemsCount={calculateItemsCount(item)}
                      allItems={cartTotal?.quantity || 0}
                      onClickStore={() => onClickStore(item)}
                    />
                  ))}
                </>
              )}
            </div>
          ) : (
            <div className={cvaStoreDetails()}>
              <StoreMapDetailsBody
                pickupItems={
                  tempActiveStore?.amount
                    ? getProductsByIds(
                        Object.keys(tempActiveStore?.amount)
                          .filter((key) => tempActiveStore.amount[key] > 0)
                          .map(Number)
                      )
                    : []
                }
                otherDeliveryItems={
                  tempActiveStore?.amount
                    ? getProductsByIds(
                        Object.keys(tempActiveStore?.amount)
                          .filter((key) => tempActiveStore.amount[key] === 0)
                          .map(Number)
                      )
                    : []
                }
                notAvailableItems={unavailableCart()}
              />
              {/* <div className="w-[calc(100%-4.8rem)] mx-2.4 aspect-[312/221] relative">
                {tempActiveStore?.photos
                  ?.length ? (
                  <MobilePhotosSwiper
                    images={
                      tempActiveStore.photos
                    }
                  />
                ) : null}
              </div> */}
              {typeI === 'default' ? (
                <div className={cvaStoreDetails()}>
                  <div className="w-[calc(100%-4.8rem)] mx-2.4 aspect-[312/221] relative">
                    {tempActiveStore?.photos?.length ? <MobilePhotosSwiper images={tempActiveStore.photos} /> : null}
                  </div>
                </div>
              ) : selectedProduct ? (
                <div className={cvaStoreDetails()}>
                  <div className="w-full px-2.4 py-3.2 flex flex-col gap-1.6">
                    <h4 className="font-secondary text-cBlack text-h3">
                      Можно забрать <span className="text-cMediumGreen">сегодня</span>
                    </h4>
                    {selectedProduct.detail_picture ? (
                      <div className="w-9 h-9 relative rounded-[1.6rem] bg-cLightGrey p-1">
                        <div className="relative w-full h-full">
                          <Image
                            src={selectedProduct.detail_picture}
                            className="object-contain mix-blend-multiply"
                            alt={selectedProduct.name}
                            fill
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
        {showStoreDetails &&
        tempActiveStore?.amount &&
        getProductsByIds(
          Object.keys(tempActiveStore?.amount)
            .filter((key) => tempActiveStore.amount[key] > 0)
            .map(Number)
        )?.length ? (
          <div className={cvaAsideButtonWrapper()}>
            <Button
              type="button"
              className={cvaAsideButton()}
              color={'mediumGreen'}
              rootWrapperClassName="w-full"
              animateWrapperClassName="w-full"
              size={'medium'}
              hoverEffect={'highlight'}
              onClick={() => onClickSelectStore()}
              disableInViewAnimation
            >
              Выбрать магазин
            </Button>
          </div>
        ) : null}
      </BottomSheet>
    </motion.div>
  );
};

export default StoresMap;
