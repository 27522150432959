import { ICity, IShop } from '@/shared/api/getShops/types';
import { IAmountItem } from '@/shared/api/postAvailByCity/types';
import { wordEnding } from '@/shared/utils/wordEnding';

interface Params {
  storesByCity?: ICity;
  amountShops?: IAmountItem[];
  onCityShopPlacemarkClick?: (shop: IShop) => void;
  onAmountShopPlacemarkClick?: (shop: IAmountItem) => void;
  calculateItemsCount?: (item: IAmountItem) => number;
  cartTotal?: {
    quantity: number;
    summary: number;
  };
}

export const initClusterer = ({
  storesByCity,
  amountShops,
  onCityShopPlacemarkClick,
  onAmountShopPlacemarkClick,
  calculateItemsCount,
  cartTotal,
}: Params) => {
  const clusterer = new (window as any).ymaps.Clusterer({
    preset: 'islands#invertedVioletClusterIcons',
    groupByCoordinates: false,
    clusterDisableClickZoom: false,
    clusterHideIconOnBalloonOpen: false,
    geoObjectHideIconOnBalloonOpen: false,
    clusterIcons: [
      {
        href: '/icons/cluster.svg',
        size: [48, 48],
        offset: [-24, -24],
      },
    ],
    clusterIconContentLayout: (window as any).ymaps.templateLayoutFactory.createClass(
      '<div style="color: #213D38; font-weight: 600; font-size: 16px;">$[properties.geoObjects.length]</div>'
    ),
  });

  let placemarks = [];

  if (storesByCity?.shops?.length) {
    placemarks = renderStoreMapPlacemarks({
      storesByCity,
      onCityShopPlacemarkClick,
    });
  } else if (amountShops) {
    placemarks = renderStoreMapPlacemarks({
      amountShops,
      onAmountShopPlacemarkClick,
      calculateItemsCount,
      cartTotal,
    });
  }

  clusterer.add(placemarks);

  return clusterer;
};

function renderStoreMapPlacemarks({
  storesByCity,
  amountShops,
  onCityShopPlacemarkClick,
  onAmountShopPlacemarkClick,
  calculateItemsCount,
  cartTotal,
}: Params) {
  const placemarks: any = [];

  if (storesByCity) {
    storesByCity.shops.forEach((item) => {
      const iconContentLayout = (window as any).ymaps.templateLayoutFactory.createClass(
        placeMarkLayout(undefined, undefined, item.amount),
        {
          build: function () {
            iconContentLayout.superclass.build.call(this);
          },
          clear: function () {
            iconContentLayout.superclass.clear.call(this);
          },
        }
      );

      const placemark = new (window as any).ymaps.Placemark(
        item.map.split(','),
        {
          hintContent: '',
        },
        {
          iconLayout: iconContentLayout,
          iconShape: {
            type: 'Rectangle',
            coordinates: [
              [36, 56],
              [-18, -56],
            ],
          },
        }
      );

      placemark.events.add('click', function () {
        onCityShopPlacemarkClick?.(item);
      });

      placemarks.push(placemark);
    });
  } else if (amountShops) {
    amountShops.forEach((item) => {
      const iconContentLayout = (window as any).ymaps.templateLayoutFactory.createClass(
        placeMarkLayout(
          calculateItemsCount?.(item) || Object.values(item.amount).filter((value) => value > 0).length,
          cartTotal?.quantity || Object.keys(item.amount).length
        ),
        {
          build: function () {
            iconContentLayout.superclass.build.call(this);
          },
          clear: function () {
            iconContentLayout.superclass.clear.call(this);
          },
        }
      );

      const placemark = new (window as any).ymaps.Placemark(
        [item.store.gps_n, item.store.gps_s],
        {
          hintContent: '',
        },
        {
          iconLayout: iconContentLayout,
          iconShape: {
            type: 'Rectangle',
            coordinates: [
              [36, 56],
              [-18, -56],
            ],
          },
        }
      );

      placemark.events.add('click', function () {
        onAmountShopPlacemarkClick?.(item);
      });

      placemarks.push(placemark);
    });
  }

  return placemarks;
}

function placeMarkLayout(itemsCount?: number, allItems?: number, amount?: number) {
  return `
    <div class="map-marker">
      ${
        itemsCount || itemsCount === 0
          ? `<div class="map-marker__count ${allItems === itemsCount ? 'map-marker__count--all' : ''}">
        <img src="${allItems === itemsCount ? '/icons/check-white.svg' : '/icons/wait.svg'}" alt="" />
        <span>${
          itemsCount === allItems
            ? 'Все товары'
            : `${itemsCount} ${wordEnding(itemsCount, ['товар', 'товара', 'товаров'])} из ${allItems}`
        }</span>
      </div>`
          : amount
            ? amountLayout(amount)
            : ''
      }
      <svg width="37" height="56" viewBox="0 0 37 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.4688 55C25.0962 55 30.4688 52.3137 30.4688 49C30.4688 45.6863 25.0962 43 18.4688 43C11.8413 43 6.46875 45.6863 6.46875 49C6.46875 52.3137 11.8413 55 18.4688 55Z" fill="#14642E"/>
        <path d="M12.0052 7.48926L7.92188 16.2393L10.8385 25.5726H19.0052L26.5886 24.406V13.9059L23.0886 9.23926L12.0052 7.48926Z" fill="#14642E"/>
        <path d="M31.3818 5.43955C28.2283 2.13451 23.6708 0 18.4918 0C13.2897 0 8.7552 2.13451 5.55571 5.43955C2.35623 8.74459 0.46875 13.2202 0.46875 17.9253C0.46875 20.6106 1.06722 23.3419 2.40226 25.9583L13.3818 47.8543C14.3946 49.9199 16.5122 51.0446 18.4918 50.9986C20.4713 51.0216 22.5659 49.9199 23.5787 47.8543L34.5813 25.9583C35.8933 23.3189 36.4688 20.6106 36.4688 17.9253C36.4688 13.2431 34.5813 8.74459 31.3818 5.43955ZM18.4688 24.3288C14.901 24.3288 12.0007 21.4369 12.0007 17.8794C12.0007 14.3218 14.901 11.4299 18.4688 11.4299C22.0365 11.4299 24.9368 14.3218 24.9368 17.8794C24.9598 21.4369 22.0595 24.3288 18.4688 24.3288Z" fill="#3DC969"/>
      </svg>
    </div>
  `;
}

function amountLayout(value: number) {
  const availabilityCasesText = () => {
    switch (true) {
      case value === 0:
        return 'Нет в наличии';
      case value === 1:
        return 'Осталось мало';
      case value > 1 && value <= 3:
        return 'Осталось мало';
      case value > 1 && value < 5:
        return 'Осталось средне';
      case value >= 5:
        return 'Осталось много';
      default:
        return 'Нет в наличии';
    }
  };

  return `<div
      class="map-marker__count inline-flex items-center !gap-0.4 text-bodyM !font-semibold whitespace-nowrap !py-0.4 !px-0.8 pl-0.8 text-cWhite rounded-[0.8rem] ${
        value >= 5
          ? '!bg-cMediumGreen'
          : value > 3 && value < 5
            ? '!bg-cExtraOrange'
            : value === 0
              ? '!bg-cBlack'
              : '!bg-cRed'
      }"
    >
      ${
        value > 0
          ? `
        <svg
          class="w-1.6 h-1.6 rotate-[45deg]"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="6.86914"
            y="4.60547"
            width="3.2"
            height="4.8"
            rx="1.6"
            transform="rotate(135 6.86914 4.60547)"
            fill="white"
            opacity="${value >= 1 ? 1 : 0.32}"
          />
          <rect
            x="4.7998"
            y="6.40039"
            width="3.2"
            height="4.8"
            rx="1.6"
            transform="rotate(90 4.7998 6.40039)"
            fill="white"
            opacity="${value >= 2 ? 1 : 0.32}"
          />
          <rect
            x="4.60625"
            y="9.13096"
            width="3.2"
            height="4.8"
            rx="1.6"
            transform="rotate(45 4.60625 9.13096)"
            fill="white"
            opacity="${value >= 3 ? 1 : 0.32}"
          />
          <rect
            x="6.40039"
            y="11.2002"
            width="3.2"
            height="4.8"
            rx="1.6"
            fill="white"
            opacity="${value >= 4 ? 1 : 0.32}"
          />
          <rect
            x="14.7871"
            y="12.5254"
            width="3.2"
            height="4.8"
            rx="1.6"
            transform="rotate(135 14.7871 12.5254)"
            fill="white"
            opacity="${value >= 5 ? 1 : 0.32}"
          />
          <rect
            x="16"
            y="6.40039"
            width="3.2"
            height="4.8"
            rx="1.6"
            transform="rotate(90 16 6.40039)"
            fill="white"
            opacity="${value >= 6 ? 1 : 0.32}"
          />
          <rect
            x="12.5254"
            y="1.21191"
            width="3.2"
            height="4.8"
            rx="1.6"
            transform="rotate(45 12.5254 1.21191)"
            fill="white"
            opacity="${value >= 7 ? 1 : 0.32}"
          />
          <rect
            x="6.40039"
            width="3.2"
            height="4.8"
            rx="1.6"
            fill="white"
            opacity="${value >= 8 ? 1 : 0.32}"
          />
        </svg>
      `
          : `<svg class="w-1.6 h-1.6" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_8_144)">
            <path d="M12.9476 3.04808C11.681 1.78261 9.93189 1 8 1C4.13401 1 1 4.13401 1 8C1 9.94 1.78919 11.6957 3.06403 12.9635M12.9476 3.04808C14.2156 4.31501 15 6.0659 15 8C15 11.866 11.866 15 8 15C6.074 15 4.32968 14.2222 3.06403 12.9635M12.9476 3.04808L3.06403 12.9635" stroke="#213D38" stroke-opacity="0.48" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_8_144">
              <rect width="16" height="16" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        `
      }

      ${availabilityCasesText()}
    </div>`;
}
