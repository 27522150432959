import { cva } from 'class-variance-authority';

export const cvaRoot = cva(['StoreMapDetailsBodyStyles-cvaROot', 'py-3.2 sm:py-4.8 px-3.2 lg:px-8']);

export const cvaGroup = cva(['StoreMapDetailsBodyStyles-cvaGroup', 'not-last:mb-4.8']);

export const cvaGroupTitle = cva(
  ['StoreMapDetailsBodyStyles-cvaGroupTitle', 'relative', 'mb-1.2', 'text-h4 font-secondary'],
  {
    variants: {
      withIcon: {
        true: ['pl-0 sm:pl-3.2'],
        false: [''],
      },
    },
    defaultVariants: {
      withIcon: false,
    },
  }
);

export const cvaGroupTitleIcon = cva([
  'StoreMapDetailsBodyStyles-cvaGroupTitleIcon',
  'sm:absolute left-0 sm:top-0.3',
  'mb-1.2 sm:mb-0',
  'w-2.4 h-2.4',
]);

export const cvaGroupDescr = cva(['StoreMapDetailsBodyStyles-cvaGroupDescr', 'text-bodyM '], {
  variants: {
    color: {
      black: ['text-cBlack text-opacity-[0.64]'],
      red: ['text-cRed'],
    },
  },
  defaultVariants: {
    color: 'black',
  },
});

export const cvaGroupTitleGreenText = cva(['StoreMapDetailsBodyStyles-cvaGroupTitleGreenText', 'text-cMediumGreen']);

export const cvaItems = cva([
  'StoreMapDetailsBodyStyles-cvaItems',
  'grid grid-cols-4 gap-0.8 sm:gap-1.2',
  'not-last:mb-2.4 mt-2.4 mb-2',
]);

export const cvaItem = cva([
  'StoreMapDetailsBodyStyles-cvaItem',
  'relative overflow-hidden',
  'p-0.8',
  'w-[7.2rem] sm:w-[9.1rem] h-[7.2rem] sm:h-[9.1rem]',
  'bg-cLightGrey',
  'rounded-[1.6rem]',
]);

export const cvaItemImageWrap = cva([
  'StoreMapDetailsBodyStyles-cvaItemImageWrap',
  'relative',
  'w-5.6 sm:w-7.5 h-5.6 sm:h-7.5',
  'bg-cLightGrey',
]);

export const cvaItemImage = cva(
  ['StoreMapDetailsBodyStyles-cvaItemImage', 'object-contain object-center', 'mix-blend-multiply'],
  {
    variants: {
      disabled: {
        true: ['opacity-[0.48]'],
        false: false,
      },
    },
    defaultVariants: {
      disabled: false,
    },
  }
);

export const showMoreItems = cva([
  'StoreMapDetailsBodyStyles-showMoreItems',
  'inline-block',
  'relative',
  'pr-1.8',
  'text-bodyM !font-semibold',
  'cursor-pointer select-none',
]);

export const showMoreItemsText = cva([
  'StoreMapDetailsBodyStyles-showMoreItemsText',
  'border-b border-0.1 border-cBlack border-opacity-[0.32] border-dashed',
]);

export const showMoreItemsIcon = cva(
  [
    'StoreMapDetailsBodyStyles-showMoreItemsIcon',
    'absolute right-0 top-0.4',
    'w-1.6 h-1.6',
    'stroke-cBlack',
    'transition-transform duration-300',
  ],
  {
    variants: {
      active: {
        true: ['-rotate-180'],
        false: [''],
      },
    },
    defaultVariants: {
      active: false,
    },
  }
);
