import { FC } from 'react';
import { cva } from 'class-variance-authority';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper/core';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import Image from 'next/image';
import { twMergeExt } from '@/shared/utils/twMergeExt';

// Инициализация модулей Swiper
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const cvaRoot = cva(['MobilePhotosSwiper-cvaRoot', 'relative', 'w-full', 'h-full']);

interface Props {
  images: string[];
  className?: string;
}

const MobilePhotosSwiper: FC<Props> = ({ images, className }) => {
  return (
    <div className={twMergeExt(cvaRoot(), className)}>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        navigation={false}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        className="w-full h-full"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="w-full h-full relative">
              <Image fill src={image} alt={`slide-${index}`} className="rounded-[1.2rem]" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MobilePhotosSwiper;
