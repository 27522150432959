const url = `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${process.env.NEXT_PUBLIC_YANDEX_MAP_API_KEY}`;

const loadYmaps = (callback: Function) => {
  const existingScript = document.getElementById('yandexMaps');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = url;
    script.id = 'yandexMaps';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export default loadYmaps;
