import { wordEnding } from '@/shared/utils/wordEnding';
import { FC } from 'react';
import {
  cvaAddress,
  cvaAddressIcon,
  cvaCount,
  cvaCountIcon,
  cvaDate,
  cvaPhone,
  cvaPhoneIcon,
  cvaRoot,
  cvaWorkingHours,
  cvaWorkingHoursIcon,
} from './StoreMapItemStyles';
import IconCaretRight from '/public/icons/arrow-right.svg';
import IconCalendar from '/public/icons/calendar.svg';
import IconCheck from '/public/icons/check-white.svg';
import IconPhone from '/public/icons/phone2.svg';
import IconWait from '/public/icons/wait.svg';

interface Props {
  address: string;
  date?: string;
  workingHours: string;
  itemsCount: number;
  allItems: number;
  phone?: string;
  onClickStore: () => void;
}

const StoreMapItem: FC<Props> = ({ address, date, workingHours, itemsCount, allItems, onClickStore, phone }) => {
  return (
    <div className={cvaRoot()} onClick={() => onClickStore()}>
      <address className={cvaAddress()}>
        {address}
        <IconCaretRight className={cvaAddressIcon()} />
      </address>
      {phone ? (
        <div className={cvaPhone()}>
          <IconPhone className={cvaPhoneIcon()} /> {phone}
        </div>
      ) : null}
      {date ? (
        <div className={cvaDate()}>
          Можно забрать&nbsp;<span>{date}</span>
        </div>
      ) : null}
      <div className={cvaWorkingHours()}>
        <IconCalendar className={cvaWorkingHoursIcon()} /> {workingHours}
      </div>
      {itemsCount || itemsCount === 0 ? (
        <div
          className={cvaCount({
            allItems: allItems === itemsCount,
          })}
        >
          {allItems === itemsCount ? <IconCheck className={cvaCountIcon()} /> : <IconWait className={cvaCountIcon()} />}
          {itemsCount === allItems
            ? 'Все товары'
            : `${itemsCount} ${wordEnding(itemsCount, ['товар', 'товара', 'товаров'])} из ${allItems}`}
        </div>
      ) : null}
    </div>
  );
};

export default StoreMapItem;
