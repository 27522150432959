import { FC } from 'react';
import {
  cvaAddress,
  cvaHowToGet,
  cvaHowToGetDescr,
  cvaHowToGetTitle,
  cvaPhone,
  cvaPhoneIcon,
  cvaRoot,
  cvaTitle,
  cvaWorkingHours,
  cvaWorkingHoursIcon,
} from './StoreMapDetailsHeaderStyles';
import IconCalendar from '/public/icons/calendar.svg';
import IconPhone from '/public/icons/phone2.svg';

interface Props {
  address?: string;
  workingHours?: string;
  phone?: string;
  howToGet?: string;
  timeToStore: string;
}

const StoreMapDetailsHeader: FC<Props> = ({ address, workingHours, phone, howToGet }) => {
  return (
    <div className={cvaRoot()}>
      <div className={cvaTitle()}>Подробнее о магазине</div>
      <address className={cvaAddress()}>{address}</address>
      {workingHours ? (
        <div className={cvaWorkingHours()}>
          <IconCalendar className={cvaWorkingHoursIcon()} /> {workingHours}
        </div>
      ) : null}
      {phone ? (
        <a href={`tel:${phone}`} className={cvaPhone()}>
          <IconPhone className={cvaPhoneIcon()} /> {phone}
        </a>
      ) : null}
      {howToGet ? (
        <dl className={cvaHowToGet()}>
          <dt className={cvaHowToGetTitle()}>Как добраться</dt>
          <dd className={cvaHowToGetDescr()}>{howToGet}</dd>
        </dl>
      ) : null}
      {/* <dl className={cvaTimeToStore()}>
        <dt className={cvaTimeToStoreTitle()}>
          Срок хранения заказа
        </dt>
        <dd className={cvaTimeToStoreDescr()}>
          {timeToStore}
        </dd>
      </dl> */}
    </div>
  );
};

export default StoreMapDetailsHeader;
